export const isIncludedIn = (searchValue: string, content: string) => {
  const regExpPattern = new RegExp(
    sanitizeRegExp(removeDiacritics(searchValue)),
    'gi'
  )
  return regExpPattern.test(removeDiacritics(content))
}

// This method is based on this comment response: https://stackoverflow.com/questions/3446170/escape-string-for-use-in-javascript-regex
const sanitizeRegExp = (string: string) => {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') // $& means the whole matched string
}

const removeDiacritics = (text: string) => {
  return text.normalize('NFD').replace(/[\u0300-\u036F]/g, '')
}
